@import '../css/variables.scss';

.franja {
  border-top: 1px solid $secondary;
  background: linear-gradient(45deg, $secondary, $secondary);
  color: $white;

  .join-title {
    margin-bottom: 1.5rem;
  }
}

@import '../../css/variables';

#join {
  background: linear-gradient(45deg, $primary, $tertiary);
  color: $white;

  .join-title {
    margin-bottom: 1.5rem;
  }
}

.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;

  .footer-text {
    color: rgba(255, 255, 255, 0.6);
  }
}

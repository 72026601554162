@import './variables.scss';

$theme-colors: (
  'primary': $primary,
  'light': $light,
  'secondary': $secondary,
);

// Import Bootstrap and its default variables
@import 'bootstrap/scss/bootstrap';

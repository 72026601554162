@import '../../css/variables.scss';

.consulting {
  .consulting-title {
    span {
      color: $primary;
    }
  }
  .consulting-description {
    h2 {
      font-size: 2rem;
    }

    p {
      font-size: 1.25rem;
    }
  }

  .row:nth-child(2n) > .consulting-asset {
    @media (min-width: 768px) {
      order: 2;
    }
  }

  .consulting-asset {
    display: flex;
    justify-content: center;
    align-items: center;

    .consulting-asset-image-container {
      .consulting-asset-image {
        width: 100%;
        border-radius: 5%;
        max-width: 20rem;
        max-height: 20rem;
      }
    }
  }
}

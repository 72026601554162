.resource {
  .card {
    transition: box-shadow 0.3s;
    border: 1px solid #ccc;

    &:hover {
      box-shadow: 0 0 11px rgba(33, 33, 33, 0.5);
    }
  }

  .card-img-top {
    width: 100%;
    height: 300px;
  }
}

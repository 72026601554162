// Colours
$white: #fff;
$fieryRose: #ff5864;
$tertiary: #fd297b;

// bootstrap
$primary: #ff655b;
$secondary: #008bf8;
$light: #eeeeee;

// Custom variables
$navbar-height: 72px;
